<template>
  <Frame :width="3840" :height="2160" bgColor="">
    <div id="page-body">
      <topHeader @reload="change" :anjie="anjie"></topHeader>
      <div class="container">
        <!-- <myLeft @change="changeMap" :moneyRank="moneyRank" ref="myLeft"></myLeft> -->
        <myLeft :moneyRank="moneyRank" ref="myLeft" :anjie="anjie"></myLeft>
        <myBottom ref="myBottom"></myBottom>
      </div>
    </div>
  </Frame>
</template>

<script>
import Frame from "../../components/Frame";
import topHeader from "@/views/index/components/top-header";
import myLeft from "@/views/index/components/left";
import myBottom from "@/views/index/components/bottom";
import http from "../../http/index";
import moment from "moment";

export default {
  components: {
    Frame,
    topHeader,
    myLeft,
    myBottom,
  },
  computed: {},
  data() {
    return {
      showMap: true,
      socket: "",
      times: null,
      baseData: [],
      opt2: [],
      moneyRank: [],
      anjie:false
    };
  },
  mounted() {
    // console.log(this.GetUrlParam("index"));
    if (this.GetUrlParam("name") == "unique") {
      this.initFunA();
    } else if (this.GetUrlParam("name") == "anjie") {
      this.anjie = true  //娄葑是小地图
      // this.initFunB();
    }
    // 新停车大屏
    if(this.GetUrlParam("od").length > 0){
      if (this.GetUrlParam("name") == "anjie"){
        this.initFunC(this.GetUrlParam("od"),this.GetUrlParam("rd"),"aj");
      }else {
        this.initFunC(this.GetUrlParam("od"),this.GetUrlParam("rd"),"uq");
      }
    }
  },
  methods: {
    change(e) {
      console.log(e, "change");

      // console.log(this.GetUrlParam("index"));
      if (this.GetUrlParam("name") == "unique") {
        this.timingFunA();
      } else if (this.GetUrlParam("name") == "anjie") {
        // this.timingFunB();
      }
      // 新停车大屏
      if(this.GetUrlParam("od").length > 0){
          if (this.GetUrlParam("name") == "anjie"){
            this.timingFunC(this.GetUrlParam("od"),this.GetUrlParam("rd"),"aj");
          }else {
            this.timingFunC(this.GetUrlParam("od"),this.GetUrlParam("rd"),"uq");
          }
      }
    },
    timingFunA() {
      //十分钟一次
      const time = new Date();
      //当日营收排行
      http.GetMoneyRank().then((res) => {
        this.$refs.myLeft.list = res.slice(0, 9);
      });
      //今日总
      http.GetTodayAllMoney().then((res) => {
        // console.log(res);
        this.$refs.myLeft.initTodayAllMoney(Number(res[0].value));
      });
      //减免
      http.GetTodayFreeMoney().then((res) => {
        // console.log(res);
        this.$refs.myLeft.initTodayFreeMoney(Number(res[0].value));
      });
      //今日交易笔数
      http.GetTodayAllOrderNum().then((res) => {
        // console.log(res);
        this.$refs.myLeft.initTodayAllOrderNum(Number(res[0].value));
      });
      //一小时一次
      if (time.getMinutes() == 0 && time.getSeconds() == 0) {
        //道闸在线
        http.GetOnlineCameraNum().then((res) => {
          this.$refs.myBottom.listB[0].num = res[0].value;
        });
        //道闸离线
        http.GetOfflineCameraNum().then((res) => {
          this.$refs.myBottom.listB[1].num = res[0].value;
        });
        //岗亭在线
        http.GetOnlineStationNum().then((res) => {
          this.$refs.myBottom.listB[2].num = res[0].value;
        });
        //岗亭离线
        http.GetOfflineStationNum().then((res) => {
          this.$refs.myBottom.listB[3].num = res[0].value;
        });
      }
      //三小时一次
      if (time.getHours % 3 == 0 && time.getMinutes() == 0 && time.getSeconds() == 0) {
        //支付类型
        http.GetPayTypeCount().then((res) => {
          this.$refs.myBottom.initB(res);
        });
      }
      //凌晨两点
      if (time.getHours() == 2 && time.getMinutes() == 0 && time.getSeconds() == 0) {
        //本月上月金额
        http.GetAllMoneyOneMonth().then((res) => {
          this.$refs.myBottom.AllMoneyOneMonth = res;
        });
        //用户总数
        http.GetTotalUserNum().then((res) => {
          this.$refs.myBottom.TotalUserNum = res;
        });
        //车辆总数
        http.GetTotalCarNum().then((res) => {
          this.$refs.myBottom.TotalCarNum = res;
        });
        //7天
        http.GetLastWeekMoney().then((res) => {
          // console.log(res);
          this.$refs.myBottom.initA(res);
        });
      }
    },
    timingFunB() {
      //十分钟一次
      const time = new Date();
      //当日营收排行
      http.GetMoneyRank2().then((res) => {
        this.$refs.myLeft.list = res.slice(0, 9);
      });
      //今日总
      http.GetTodayAllMoney2().then((res) => {
        // console.log(res);
        this.$refs.myLeft.initTodayAllMoney(Number(res[0].value));
      });
      //减免
      http.GetTodayFreeMoney2().then((res) => {
        // console.log(res);
        this.$refs.myLeft.initTodayFreeMoney(Number(res[0].value));
      });
      //今日交易笔数
      http.GetTodayAllOrderNum2().then((res) => {
        // console.log(res);
        this.$refs.myLeft.initTodayAllOrderNum(Number(res[0].value));
      });
      //一小时一次
      if (time.getMinutes() == 0 && time.getSeconds() == 0) {
        //道闸在线
        http.GetOnlineCameraNum2().then((res) => {
          this.$refs.myBottom.listB[0].num = res[0].value;
        });
        //道闸离线
        http.GetOfflineCameraNum2().then((res) => {
          this.$refs.myBottom.listB[1].num = res[0].value;
        });
        //岗亭在线
        http.GetOnlineStationNum2().then((res) => {
          this.$refs.myBottom.listB[2].num = res[0].value;
        });
        //岗亭离线
        http.GetOfflineStationNum2().then((res) => {
          this.$refs.myBottom.listB[3].num = res[0].value;
        });
      }
      //三小时一次
      if (time.getHours % 3 == 0 && time.getMinutes() == 0 && time.getSeconds() == 0) {
        //支付类型
        http.GetPayTypeCount2().then((res) => {
          this.$refs.myBottom.initB(res);
        });
      }
      //凌晨两点
      if (time.getHours() == 2 && time.getMinutes() == 0 && time.getSeconds() == 0) {
        //本月上月金额
        http.GetAllMoneyOneMonth2().then((res) => {
          this.$refs.myBottom.AllMoneyOneMonth = res;
        });
        //用户总数
        http.GetTotalUserNum2().then((res) => {
          console.log(res,'res')
          this.$refs.myBottom.TotalUserNum = res;
        });
        //车辆总数
        http.GetTotalCarNum2().then((res) => {
          this.$refs.myBottom.TotalCarNum = res;
        });
        //7天
        http.GetLastWeekMoney2().then((res) => {
          // console.log(res);
          this.$refs.myBottom.initA(res);
        });
      }
    },
    timingFunC(param,param2,param3) {
      //十分钟一次
      const time = new Date();

      //一小时一次
      if (time.getMinutes() == 0 && time.getSeconds() == 0) {
        //当日营收排行
        http.GetProfitRanking(param,param2,param3).then((res) => {
          this.$refs.myLeft.list = res.result.slice(0,9);
        });
        //支付类型
        http.GetPayTypeNum(param,param2,param3).then((res) => {
          this.$refs.myBottom.initB(res.result);
        });
        //今日、减免、交易笔数
        http.GetTodayBusinessData(param,param2,param3).then((res) => {
          this.$refs.myLeft.initTodayAllMoney(Number(res.result.todayTrade));
          this.$refs.myLeft.initTodayFreeMoney(Number(res.result.todayTradeFree));
          this.$refs.myLeft.initTodayAllOrderNum(Number(res.result.todayTradeNum));
        });
        // 道闸
        http.GetCameraState(param,param2,param3).then((res) => {
          this.$refs.myBottom.listB[0].num = res.result.onlineCameraNum;
          this.$refs.myBottom.listB[1].num = res.result.unlineCameraNum;
          this.$refs.myBottom.listB[2].num = res.result.onlineStationNum;
          this.$refs.myBottom.listB[3].num = res.result.unlineStationNum;
        });

      }

      //凌晨4点
      if (time.getHours() == 4 && time.getMinutes() == 0 && time.getSeconds() == 0) {
        //7天
        http.GetSevenStatistics(param,param2,param3).then((res) => {
          // console.log(res);
          this.$refs.myBottom.initA(res.result);
        });
        //本月上月金额
        http.GetDataStatistics(param,param2,param3).then((res) => {
          this.$refs.myBottom.AllMoneyOneMonth[0].value = res.result.nowMonthTrade;
          this.$refs.myBottom.AllMoneyOneMonth[1].value = res.result.lastMonthTrade;
          this.$refs.myBottom.TotalUserNum[0].value = res.result.allUserNum;
          this.$refs.myBottom.TotalUserNum[1].value = res.result.allOrganNum;
          this.$refs.myBottom.TotalCarNum[0].value = res.result.allCarNum;
          this.$refs.myBottom.TotalCarNum[1].value = res.result.allShopNum;
        });
      }
    },
    GetUrlParam(paraName) {
      var url = document.location.toString();
      var arrObj = url.split("?");

      if (arrObj.length > 1) {
        var arrPara = arrObj[1].split("&");
        var arr;

        for (var i = 0; i < arrPara.length; i++) {
          arr = arrPara[i].split("=");

          if (arr != null && arr[0] == paraName) {
            return arr[1];
          }
        }
        return "";
      } else {
        return "";
      }
    },

    changeMap(e) {
      // console.log(e);
      this.showMap = !this.showMap;
      // console.log( this.showMap);
    },
    initFunA() {
      //当日营收排行
      http.GetMoneyRank().then((res) => {
        this.$refs.myLeft.list = res.slice(0, 9);
      });
      //支付类型
      http.GetPayTypeCount().then((res) => {
        this.$refs.myBottom.initB(res);
      });
      //7天
      http.GetLastWeekMoney().then((res) => {
        // console.log(res);
        this.$refs.myBottom.initA(res);
      });
      //本月上月金额
      http.GetAllMoneyOneMonth().then((res) => {
        this.$refs.myBottom.AllMoneyOneMonth = res;
      });
      //车辆总数
      http.GetTotalCarNum().then((res) => {
        this.$refs.myBottom.TotalCarNum = res;
      });
      //用户总数
      http.GetTotalUserNum().then((res) => {
        this.$refs.myBottom.TotalUserNum = res;
        this.$refs.myLeft.TotalUserNum2 = res;
      });
      //道闸在线
      http.GetOnlineCameraNum().then((res) => {
        this.$refs.myBottom.listB[0].num = res[0].value;
      });
      //道闸离线
      http.GetOfflineCameraNum().then((res) => {
        this.$refs.myBottom.listB[1].num = res[0].value;
      });
      //岗亭在线
      http.GetOnlineStationNum().then((res) => {
        this.$refs.myBottom.listB[2].num = res[0].value;
      });
      //岗亭离线
      http.GetOfflineStationNum().then((res) => {
        this.$refs.myBottom.listB[3].num = res[0].value;
      });
      //今日总
      http.GetTodayAllMoney().then((res) => {
        // console.log(res);
        this.$refs.myLeft.initTodayAllMoney(Number(res[0].value));
      });
      //减免
      http.GetTodayFreeMoney().then((res) => {
        // console.log(res);
        this.$refs.myLeft.initTodayFreeMoney(Number(res[0].value));
      });
      //今日交易笔数
      http.GetTodayAllOrderNum().then((res) => {
        // console.log(res);
        this.$refs.myLeft.initTodayAllOrderNum(Number(res[0].value));
      });
    },
    initFunB() {
      //当日营收排行
      http.GetMoneyRank2().then((res) => {
        this.$refs.myLeft.list = res.slice(0, 9);
      });
      //支付类型
      http.GetPayTypeCount2().then((res) => {
        this.$refs.myBottom.initB(res);
      });
      //7天
      http.GetLastWeekMoney2().then((res) => {
        // console.log(res);
        this.$refs.myBottom.initA(res);
      });
      //本月上月金额
      http.GetAllMoneyOneMonth2().then((res) => {
        this.$refs.myBottom.AllMoneyOneMonth = res;
      });
      //车辆总数
      http.GetTotalCarNum2().then((res) => {
        this.$refs.myBottom.TotalCarNum = res;
      });
      //用户总数
      http.GetTotalUserNum2().then((res) => {
        this.$refs.myBottom.TotalUserNum = res;
      });
      //道闸在线
      http.GetOnlineCameraNum2().then((res) => {
        this.$refs.myBottom.listB[0].num = res[0].value;
      });
      //道闸离线
      http.GetOfflineCameraNum2().then((res) => {
        this.$refs.myBottom.listB[1].num = res[0].value;
      });
      //岗亭在线
      http.GetOnlineStationNum2().then((res) => {
        this.$refs.myBottom.listB[2].num = res[0].value;
      });
      //岗亭离线
      http.GetOfflineStationNum2().then((res) => {
        this.$refs.myBottom.listB[3].num = res[0].value;
      });
      //今日总
      http.GetTodayAllMoney2().then((res) => {
        // console.log(res);
        this.$refs.myLeft.initTodayAllMoney(Number(res[0].value));
      });
      //减免
      http.GetTodayFreeMoney2().then((res) => {
        // console.log(res);
        this.$refs.myLeft.initTodayFreeMoney(Number(res[0].value));
      });
      //今日交易笔数
      http.GetTodayAllOrderNum2().then((res) => {
        // console.log(res);
        this.$refs.myLeft.initTodayAllOrderNum(Number(res[0].value));
      });
    },

    // 新平台
    initFunC(param,param2,param3){
      //当日营收排行
      http.GetProfitRanking(param,param2,param3).then((res) => {
        this.$refs.myLeft.list = res.result.slice(0,9);
      });
      //支付类型
      http.GetPayTypeNum(param,param2,param3).then((res) => {
        this.$refs.myBottom.initB(res.result);
      });
      //7天营业额
      http.GetSevenStatistics(param,param2,param3).then((res) => {
        // console.log(res);
        this.$refs.myBottom.initA(res.result);
      });
      //本月上月金额
      http.GetDataStatistics(param,param2,param3).then((res) => {
        this.$refs.myBottom.AllMoneyOneMonth[0].value = res.result.nowMonthTrade;
        this.$refs.myBottom.AllMoneyOneMonth[1].value = res.result.lastMonthTrade;
        this.$refs.myBottom.TotalUserNum[0].value = res.result.allUserNum;
        this.$refs.myBottom.TotalUserNum[1].value = res.result.allOrganNum;
        this.$refs.myLeft.TotalUserNum2[1].value = res.result.allOrganNum;
        this.$refs.myBottom.TotalCarNum[0].value = res.result.allCarNum;
        this.$refs.myBottom.TotalCarNum[1].value = res.result.allShopNum;
      });
      //今日、减免、交易笔数
      http.GetTodayBusinessData(param,param2,param3).then((res) => {
        this.$refs.myLeft.initTodayAllMoney(Number(res.result.todayTrade));
        this.$refs.myLeft.initTodayFreeMoney(Number(res.result.todayTradeFree));
        this.$refs.myLeft.initTodayAllOrderNum(Number(res.result.todayTradeNum));
      });
      //道闸状态
      http.GetCameraState(param,param2,param3).then((res) => {
        this.$refs.myBottom.listB[0].num = res.result.onlineCameraNum;
        this.$refs.myBottom.listB[1].num = res.result.unlineCameraNum;
        this.$refs.myBottom.listB[2].num = res.result.onlineStationNum;
        this.$refs.myBottom.listB[3].num = res.result.unlineStationNum;
      });
    }
  },
};
</script>

<style lang="less" scoped>
#page-body {
  width: 100%;
  height: 100%;
  // background: rgb(50, 48, 84);
  background-image: url("../../assets/bg.png");
  background-size: 100% 100%;
  .container {
    margin: 34px 48px;
  }
}

.active {
  background-image: url("../../assets/bg.png");
  // background-image: url("../../assets/bg.gif");
}
</style>
