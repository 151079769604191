//近七日营收统计折线图
let opt1 = {
  grid: {
    left: "15%",
  },
  // animationDuration: 0, //这里两个动画设置可以让图表更顺滑
  // animationEasing: "cubicInOut", //这里两个动画设置可以让图表更顺滑,
  xAxis: {
    type: "category",
    boundaryGap: false,
    axisTick: {
      show: false, // 是否显示坐标轴刻度
    },
    data: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
    axisLabel: {
      show: true, // 是否显示刻度标签
      color: "#7884A5", // 刻度标签文字的颜色
      fontSize: "24", // 文字字体大小
      margin: 30, // 刻度标签与轴线之间的距离
    },
  },
  yAxis: {
    type: "value",
    /* 改变y轴颜色 */
    axisLine: {
      show: true,
      lineStyle: {
        color: "#273554",
        width: 2, // 这里是为了突出显示加上的
      },
    },
    axisLabel: {
      show: true, // 是否显示刻度标签
      color: "#A7D2FF", // 刻度标签文字的颜色
      verticalAlign: "left", // 文字垂直对齐方式，默认自动（'top'，'middle'，'bottom'
      margin: 20,
      fontSize: "24", // 文字字体大小
    },
    axisTick: {
      show: false, // 是否显示坐标轴刻度
    },
    splitLine: {
      lineStyle: {
        // 设置背景横线
        color: "#7884A533",
      },
    },
    splitNumber: 4,
  },
  series: [
    {
      data: [8120, 11932, 339011, 32934, 125290, 112330, 200000.12],
      type: "line",
      smooth: false, //平滑曲线
      symbol: "image://https://cdn.uxianxin.com/tingLargeSrceen/line-dot.png",
      symbolSize: 50, // 折线点的大小
      itemStyle: {
        normal: {
          // color: "#FFF", // 折线点的颜色
          // borderColor: '#0FA8E0', //拐点边框颜色

          borderWidth: 2, //拐点边框大小
          margin: 8,
          label: {
            show: true,
            textStyle: {
              color: "#fff",
              backgroundColor: {
                image: require("../../../assets/line-bg.png"),
              }, // 标题背景色
              padding: 9,
              fontSize: 24, // 字体大小
              borderRadius: 8, // 圆角半径
              lineHeight: 26,
            },
            // position:[ -20, -70],
          },
        },
      },
      lineStyle: {
        color: "#0FA8E0", // 折线点的颜色
      },
      areaStyle: {
        color: {
          type: "linear",
          x: 0,
          y: 0,
          x2: 0,
          y2: 1,
          colorStops: [
            {
              offset: 0,
              color: " rgba(7, 120, 236,0.3)", // 0% 处的颜色
            },
            {
              offset: 1,
              color: "rgba(17, 176, 247,0)", //   100% 处的颜色
            },
          ],
          global: false, // 缺省为 false
        },
      },
    },
  ],
};
//当日支付类型统计
let opt2 = {
  legend: {
    bottom: "1%",
    // left: "center",
    icon: "pin",
    itemWidth: 60, // 设置图例图形的宽
    itemHeight: 10, // 设置图例图形的高
    textStyle: {
      color: "#A7D2FF", // 图例文字颜色
      fontSize: 24,
    },
    itemGap: 15,
  },
  graphic: [
    {
      type: "text",
      left: "center",
      top: "45%",
      style: {
        text: "支付类型 \n\n 统计",
        textAlign: "center",
        fill: "#A7D2FF",
        fontSize: 28,
      },
    },
    {
      type: "image", // 图形元素类型
      id: "logo", // 更新或删除图形元素时指定更新哪个图形元素，如果不需要用可以忽略。
      z: 99999, // 层叠
      left: "center",
      top: "center",
      bounding: "all", // 决定此图形元素在定位时，对自身的包围盒计算方式
      style: {
        image: "https://cdn.uxianxin.com/tingLargeSrceen/pie-bg.png", // 该图片为https开头或base64在线链接图片
        width: 400,
        height: 400,
      },
    },
  ],
  color: ["#AA840B", "#BE7103", "#399F60", "#3AA5C4", "#257AD6", "#686868"],
  series: [
    {
      name: "Access From",
      type: "pie",
      radius: ["36%", "56%"],
      avoidLabelOverlap: true,
      label: {
        normal: {
          height: 70,
          formatter: "{b|{b}}  {d|{d}%}", //图形外文字上下显示
          borderWidth: 20,
          borderRadius: 4,
          padding: [100, -240], //文字和图的边距
          rich: {
            b: {
              fontSize: 24,
              color: "#A7D2FF",
            },
            d: {
              fontSize: 32,
              color: "#FFF",
              align: "center",
              fontWeight: "bold",
            },
          },
        },
      },
      labelLine: {
        normal: {
          show: true,
          length: 40,
          length2: 240,
          lineStyle: {
            color: "#9DABD3",
            width: 2,
            cap: "square",
          },
        },
      },
      data: [],
    },
  ],
};

let opt3 = {
  geo: [
    {
      map: "china",
      top: "30%",
      left: "25%",
      zlevel: 5,
      zoom: 1.5,
      aspectScale: 1.105,
      label: {
        normal: {
          show: false,
        },
        emphasis: {
          show: true,
          textStyle: {
            color: "#FFF", //鼠标经过字体颜色
            fontSize: 24,
          },
        },
      },
      itemStyle: {
        emphasis: {
          show: true,
          shadowBlur: 20,
          areaColor: "rgba(255, 206, 56,0.9)",
          color: "#FFF",
          label: {
            show: true,
            textStyle: {
              color: "#FFF", //鼠标经过字体颜色
            },
          },
        },
        areaColor: {
          type: "radial",
          x: 0.5,
          y: 0.5,
          r: 0.5,
          colorStops: [
            {
              offset: 0,
              color: "#064DCF", // 0% 处的颜色
            },
            {
              offset: 1,
              color: "#0350B5", // 100% 处的颜色
            },
          ],
          global: false, // 缺省为 false
        }, // 背景
        borderWidth: "1", // 边框宽度
        borderColor: "#18A8FB", // 边框颜色
      },

      regions: [
        {
          name: "江苏省",
          itemStyle: {
            normal: {
              borderWidth: "3", // 边框宽度
              borderColor: "#FFEFBD", // 边框颜色
              areaColor: {
                type: "radial",
                x: 0.5,
                y: 0.5,
                r: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: "#FFEFBD", // 0% 处的颜色
                  },
                  {
                    offset: 1,
                    color: "#FFCE38", // 100% 处的颜色
                  },
                ],
                global: false, // 缺省为 false
              }, // 背景
            },
          },
        },
      ],
    },
    {
      map: "china",
      top: "31%",
      left: "25%",
      zoom: 1.5,
      aspectScale: 1.105,
      zlevel: 4,
      label: {
        normal: {
          show: false,
        },
        emphasis: {
          show: false,
        },
      },
      itemStyle: {
        emphasis: {
          show: false,
        },
        color: "#3C5FA1", // 背景
        borderWidth: "1", // 边框宽度
        borderColor: "#3C5FA1", // 边框颜色
      },
      regions: [
        {
          name: "南海诸岛",
          itemStyle: {
            normal: {
              borderWidth: "3", // 边框宽度
              borderColor: "#FFEFBD", // 边框颜色
              areaColor: {
                type: "radial",
                x: 0.5,
                y: 0.5,
                r: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: "#FFEFBD", // 0% 处的颜色
                  },
                  {
                    offset: 1,
                    color: "#FFCE38", // 100% 处的颜色
                  },
                ],
                global: false, // 缺省为 false
              }, // 背景
            },
          },
        },
      ],
    },

    {
      map: "china",
      top: "32%",
      left: "25%",
      zoom: 1.5,
      aspectScale: 1.105,
      zlevel: 4,
      label: {
        normal: {
          show: false,
        },
        emphasis: {
          show: false,
        },
      },
      itemStyle: {
        emphasis: {
          show: false,
        },
        color: "#3C5FA1", // 背景
        borderWidth: "1", // 边框宽度
        borderColor: "#3C5FA1", // 边框颜色
      },
    },
    {
      map: "china",
      top: "33%",
      left: "25%",
      zoom: 1.5,
      aspectScale: 1.105,
      zlevel: 4,
      label: {
        normal: {
          show: false,
        },
        emphasis: {
          show: false,
        },
      },
      itemStyle: {
        emphasis: {
          show: false,
        },
        color: "#3C5FA1", // 背景
        borderWidth: "1", // 边框宽度
        borderColor: "#3C5FA1", // 边框颜色
      },
    },
    {
      map: "china",
      top: "34%",
      left: "25%",
      zoom: 1.5,
      aspectScale: 1.105,
      zlevel: 4,
      label: {
        normal: {
          show: false,
        },
        emphasis: {
          show: false,
        },
      },
      itemStyle: {
        emphasis: {
          show: false,
        },
        shadowColor: "", // 外部阴影
        color: "#3C5FA1", // 背景
        borderWidth: "1", // 边框宽度
        borderColor: "#3C5FA1", // 边框颜色
      },
    },
  ],
};
let opt4 = {
  backgroundColor: "#000", //画布背景颜色
  geo: {
    show: true,
    roam: true,
    map: "suzhou",
    aspectScale: 1,
    zoom: 1.5,

    label: {
      normal: {
        show: true,
        fontSize: 24,
        color: "#0350B5",
      },
      emphasis: {
        show: false,
      },
    },

    itemStyle: {
      normal: {
        areaColor: "#01215c",
        borderWidth: 5, //设置外层边框
        borderColor: "#9ffcff",
        shadowColor: "rgba(0,54,255, 1)",
        shadowBlur: 150,
      },
    },
  },
  series: [
    // {
    //     type: 'map',
    //     map: 'suzhou',
    //     geoIndex: 1,
    //     aspectScale: 175, //长宽比
    //     showLegendSymbol: false, // 存在legend时显示
    //     label: {
    //         normal: {
    //             show: false,
    //         },
    //         emphasis: {
    //             show: false,
    //             textStyle: {
    //                 color: '#fff'
    //             }
    //         }
    //     },
    //     roam: false,
    //     itemStyle: {
    //         normal: {
    //             areaColor: '#01215c',
    //             borderColor: '#3074d0',
    //             borderWidth: 1
    //         },
    //         emphasis: {
    //             areaColor: '#01215c'
    //         }
    //     },
    // }
  ],
};
let opt5 = {
  backgroundColor: "#000", //画布背景颜色
  geo: {
    show: true,
    roam: true,
    map: "wuzhong",
    aspectScale: 1,
    zoom: 1.5,
    label: {
      normal: {
        show: true,
        fontSize: 24,
        color: "#0350B5",
      },
      emphasis: {
        show: false,
      },
    },

    itemStyle: {
      normal: {
        areaColor: "#01215c",
        borderWidth: 5, //设置外层边框
        borderColor: "#9ffcff",
        shadowColor: "rgba(0,54,255, 1)",
        shadowBlur: 150,
      },
    },
  },
  series: [
    // {
    //     type: 'map',
    //     map: 'suzhou',
    //     geoIndex: 1,
    //     aspectScale: 175, //长宽比
    //     showLegendSymbol: false, // 存在legend时显示
    //     label: {
    //         normal: {
    //             show: false,
    //         },
    //         emphasis: {
    //             show: false,
    //             textStyle: {
    //                 color: '#fff'
    //             }
    //         }
    //     },
    //     roam: false,
    //     itemStyle: {
    //         normal: {
    //             areaColor: '#01215c',
    //             borderColor: '#3074d0',
    //             borderWidth: 1
    //         },
    //         emphasis: {
    //             areaColor: '#01215c'
    //         }
    //     },
    // }
  ],
};
export default { opt1, opt2, opt3, opt4, opt5 };
